import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppStorageService } from 'src/app/services/app-storage.service';
import { UserRefreshService } from 'src/app/services/refresh-service';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})
export class LeftSideBarComponent implements OnInit {
  loginUser: any;
  imgUrl: any = environment.imgUrl;

  constructor(private toastService: ToastService, private router: Router, private appStorageService: AppStorageService, private userRefreshService: UserRefreshService) {


  }
  async ngOnInit() {
    await this.fetchLoginUser();
    this.userRefreshService.refresh$.subscribe(() => {
      this.fetchLoginUser();
    });

  }

  async fetchLoginUser() {
    const user = await this.appStorageService.getdata(environment.localSTORAGE.profile);
    // if key and value on the local storage then parse to json
    if (user) {
      this.loginUser = JSON.parse(user);

    }

    // if (this.loginUser.type === '1') {
    //   this.changeTheme('lara-dark-indigo', 'dark');

    // } else if (this.loginUser.type === '2') {
    //   this.changeTheme('tailwind-light', 'light');

    // }
  }
  async handleLogout() {
    const confirm = await this.toastService.confirm(`Are you sure you want to Sign Out?`)
    if (confirm) {
      localStorage.removeItem(environment.localSTORAGE.profile)
      localStorage.removeItem(environment.localSTORAGE.token)
      this.router.navigate(['/auth/login'])
    }

  }

  getModuleIcon(moduleName: string): string {
    const icons = {
      "Dashboard": "pi-home",
      "Users": "pi-users",
      "Admin Management": "pi-users",
      "Pending Verification": "pi-list",
      "Transaction History": "pi-wallet",
      "Activity Log": "pi-sitemap",
      "Contact Us": "pi-megaphone",
      "Sales Query": "pi-dollar",
      "Upgrade plan requests": 'pi-angle-double-up',

    };
    return icons[moduleName] || 'pi-folder';
  }

  formatModuleName(moduleName: string): string {
    return moduleName.toLowerCase().replace(/ /g, '-');
  }



}
