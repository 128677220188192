import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject, catchError, throwError } from "rxjs";
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public notify401Error: Subject<any> = new Subject();
  public API_ENDPOINT = environment.apiUrl + '/';

  constructor(protected httpClient: HttpClient,) { }

  public handle401Error(status: any) {
    this.notify401Error.next("401");
  }


  public contactus(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'engagement/contactus', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public salesQueryForm(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'engagement/salesquery', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getAllCategories(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + 'engagement/catagories/list')
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public userLogin(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/login', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public forgotPassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/forgot-password', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public sendOtp(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/sendotp', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public verifyOtp(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/verifyotp', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public register(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/register', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateStudentPassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/register', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public verifyAccount(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'auth/account/verify', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getUnverifiedCompanyDetails(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + 'admin/unverified/company/details')
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateUnverifiedCompanyProfile(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'admin/update/unverified/company/details', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public unverifiedCompanyChangePassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'admin/company/change/password', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public changeAdminPassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + 'admin/change/password', data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }



  public getCompanyList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}&type=${data.type}&status=${data.status}&country=${data.country}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public acceptVerificationRequest(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/accept/verification`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public rejectVerificationRequest(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/reject/verification`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getCompanyDetails(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/details?id=${data.id}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateCompanyProfile(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/company/update/profile`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public adminEmployeeList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/employee/list?type=${data.empStatusType}&page=${data.empPageNumber}&limit=${data.limit}&search=${data.employeeSearch}&order=${data.order}&id=${data.companyId}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getUsers(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/users?search=${data.search}&page=${data.page}&limit=${data.limit}&status=${data.status}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public addUser(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/add/user`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateUser(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/update/user`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public addSectorCategory(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/add/sector/category`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public changeCategoryAction(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/change/sector/category/action`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateSectorCategory(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/update/sector/category`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getSectorCategory(data): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/sector/category?limit=${data.limit}&order=${data.order}&page=${data.page}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public changeAdminStatus(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/update/user/status`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public deleteAdmin(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/delete/user`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public allModules(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/all/modules`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDashboardData(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/dashboard/analytics?signupfilter=${data.signUpFilter}&enrollmentfilter=${data.enrollmentFilter}&salesfilter=${data.salesFilter}&tieupsfilter=${data.tieUpsFilter}&requestofferfilter=${data.requestOfferFilter}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getEmployeeListForAdmin(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/employee/list?id=${data.id}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getTieUplist(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/tieups/list?id=${data.companyId}&page=${data.tieUpPageNumber}&limit=${data.limit}&type=${data.companyType}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getInpendingList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/inpending/list?id=${data.companyId}&page=${data.inPendingPageNumber}&limit=${data.limit}&type=${data.companyType}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getOutpendingList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/outpending/list?id=${data.companyId}&page=${data.outPendingPageNumber}&limit=${data.limit}&type=${data.companyType}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getActivityLogs(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/activity/log?page=${data.page}&limit=${data.limit}&type=${data.type}&search=${data.search}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getActivityCountDetails(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/dp/activity/countdetails?id=${data.id}&page=${data.page}&limit=${data.limit}&type=${data.type}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDRActivityCountDetails(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/dr/activity/countdetails?id=${data.id}&page=${data.page}&limit=${data.limit}&type=${data.type}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public companyWiseActivity(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/wise/activity?id=${data.companyId}&page=${data.page}&limit=${data.limit}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getCompanyWiseCollaboration(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/collaboration/list?id=${data.companyId}&page=${data.tieUpPageNumber}&limit=${data.limit}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public changeCompanyStatus(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/company/change/status`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public companyWiseInPendingList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/in-pending/list?companyId=${data.id}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public companyWiseOutPendingList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/out-pending/list?companyId=${data.id}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getContactUsList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/engagements/contactus/list?page=${data.page}&limit=${data.limit}&order=${data.order}&search=${data.search}&fromDate=${data.fromDate}&toDate=${data.toDate}`)

      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getSalesQueryList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/engagements/salesquery/list?page=${data.page}&limit=${data.limit}&order=${data.order}&search=${data.search}&fromDate=${data.fromDate}&toDate=${data.toDate}`)

      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public createTransaction(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `admin/create/transaction`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getAllTransactionList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/transaction/list?page=${data.page}&limit=${data.limit}&order=${data.order}&search=${data.search}&fromDate=${data.fromDate}&toDate=${data.toDate}&paymentMode=${data.paymentMode}&planType=${data.planType}&bankType=${data.bankType}&planStatus=${data.planStatus}`)

      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getUserDetails(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/dropdown/list`)

      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getUpgradePlanList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/upgrade-plan/request/list?&page=${data.page}&limit=${data.limit}&order=${data.order}&search=${data.search}`)

      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public getCompanyTransactions(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `admin/company/transaction/data?companyId=${data.companyId}&page=${data.page}&limit=${data.limit}&order=${data.order}`)

      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }



  // ######################################### Discount Provider
  public getDpProfile(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/company/profile`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public dpChangePassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/change/password`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public addDiscountCategory(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/add/discount-category`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public deleteDiscountCategory(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/delete/discount-category`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateCategory(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/update/discount-category`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }



  public getDiscountReceiverList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/all/list2?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}&status=${data.status}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public dpUploadProfileImage(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/upload/profile/image`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public dpUpdateIndividualImage(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/upload/individual/image`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public cancelDpRequest(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/cancel/request/collaboration`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public requestCollaboration(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/request/collaboration`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getApprovedDRList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/approved/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public getDRPendingRequests(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/in-pending/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDROutPendingRequests(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/out-pending/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public getDRRejectedRequests(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/rejected/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public handleRequestAction(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/request/action`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public endTieUpByDP(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/end-tie-up`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public search(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/search?code=${data.search}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDpNotificationList(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/notification/list`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getMyCategoryList(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/category/list`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public storeActivity(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/initiate/activity`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public DpReadNotification(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/read/notification`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public addCustomCategory(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/custom/category`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public uploadImages(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/upload/image`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public deleteCompanyImage(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/delete/image`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public addStaff(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/add/staff`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }




  // ######################################### Discount Receiver

  public drDashboardList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/dashboard/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}&status=${data.status}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }


  public getDrProfile(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/company/profile`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public uploadEmployee(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/upload/employee`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public drChangePassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/change/password`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public drUploadProfileImage(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/upload/image`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }



  public getDiscountProviderList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/all/list2?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}&status=${data.status}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getApprovedDPList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/approved/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDPPendingRequests(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/in-pending/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDPOutPendingRequests(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/out-pending/list?page=${data.page}&limit=${data.limit}&search=${data.search}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public handleDPRequestAction(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/request/action`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public requestDPCollaboration(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/request/collaboration`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public endTieUpByDR(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/end-tie-up`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public addEmployee(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/add/employee`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public deleteEmployee(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/delete/employee`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public updateEmployee(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/update/employee`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getEmployeeList(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/employee/list?type=${data.empStatusType}&page=${data.empPageNumber}&limit=${data.limit}&search=${data.employeeSearch}&order=${data.order}&id=${data.companyId}&status=${data.empStatus}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public cancelDrRequest(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/cancel/request/collaboration`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public getDrNotificationList(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/notification/list`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public changeEmpStatus(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/change/employee/status`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public searchDP(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/search?name=${data.search}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public drStoreActivity(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/initiate/activity`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public DrReadNotification(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/read/notification`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getDrTransactions(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/transaction/details?page=${data.page}&limit=${data.limit}&order=${data.order}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public upgradePlanRequest(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/upgrade-plan/request`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public getReceiverProfile(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/profile/data`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public getCurrentPlan(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/current/plan`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public checkForDowngradeAvailability(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dr/check/downgrade?planType=${data.planType}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public downgradePlan(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dr/downgrade/plan`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }


  // Staff Apis

  public getStaffProfile(): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/staff/profile`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public staffChangePassword(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/staff/change-password`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public searchByEmployee(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/staff/search?code=${data.search}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }
  public storeActivityByStaff(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/staff/initiate/activity`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public getStaffs(data: any): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + `dp/staff/list?search=${data.search}&page=${data.page}&limit=${data.limit}&status=${data.status}`)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }

  public deleteStaff(data: any): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + `dp/delete/staff`, data)
      .pipe(
        catchError((errorResponse: any) => {
          const response = errorResponse.error || {};
          return throwError(() => response);
        })
      );
  }










}
