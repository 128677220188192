import { Component } from '@angular/core';
import { AppStorageService } from 'src/app/services/app-storage.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';
import { SocketService } from 'src/app/services/socket.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  loginUser: any = ''
  notificationList: any = []
  notificationCount: any = 0
  notificationSidebar: boolean = false;
  constructor(private appstorageService: AppStorageService, private userService: UserService, private toastService: ToastService, private socketService: SocketService, private router:Router) {

  }
  ngOnInit(): void {
    let currentUser: any = (this.appstorageService.getdata(environment.localSTORAGE.profile));
    currentUser = currentUser ? JSON.parse(this.appstorageService.getdata(environment.localSTORAGE.profile)) : '';
    this.loginUser = currentUser;
    console.log(this.loginUser)
    if (this.loginUser.type === '1') {
      this.dpNotificationList()

    } else if (this.loginUser.type === '2') {
      this.drNotificationList()

    }

    this.setupSocketListeners()

  }

  dpNotificationList() {
    this.userService.getDpNotificationList().subscribe({
      next: (res) => {
        // console.log(res);
        if (res && res.statusCode === 200) {
          // this.toastService.show(res.message, true);
          this.notificationList = res.data?.notificationList;
          this.notificationCount = res.data?.unreadNotifications;
          // console.log(this.notificationList)
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      }
    });
  }
  drNotificationList() {
    this.userService.getDrNotificationList().subscribe({
      next: (res) => {
        // console.log(res);
        if (res && res.statusCode === 200) {
          // this.toastService.show(res.message, true);
          this.notificationList = res.data?.notificationList;
          this.notificationCount = res.data?.unreadNotifications;

          // console.log(this.notificationList)
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      }
    });
  }

  setupSocketListeners() {
    this.socketService.onReloadReceiverList().subscribe((id: any) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList()
          } else if (this.loginUser.type === '2') {
            this.drNotificationList()
          }
        }
      }

    })
    this.socketService.onReloadProviderList().subscribe((id) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList()
          } else if (this.loginUser.type === '2') {
            this.drNotificationList()
          }
        }
      }

    })
  }


  viewNotification() {
    this.notificationSidebar = true;
    this.notificationCount = 0;
    const payload = {
      isRead: true
    }

    if (this.loginUser.type === '1') {
      this.userService.DpReadNotification(payload).subscribe({
        next: (res) => {
          console.log(res);
          if (res && res.statusCode === 200) {
            // this.toastService.show(res.message, true);
            this.dpNotificationList()


          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    } else if (this.loginUser.type === '2') {
      this.userService.DrReadNotification(payload).subscribe({
        next: (res) => {
          console.log(res);
          if (res && res.statusCode === 200) {
            // this.toastService.show(res.message, true);
            this.drNotificationList()


          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    }


  }

  async handleLogout(){
    const confirm = await this.toastService.confirm(`Are you sure you want to Sign Out?`)
    if (confirm) {
      localStorage.removeItem(environment.localSTORAGE.profile)
      localStorage.removeItem(environment.localSTORAGE.token)
      this.router.navigate(['/auth/login'])
    }
  }

}
